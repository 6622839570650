
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("军粮列表", ["军粮管理;add_extra_point_records"])
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])

    const formData = ref({
      nickname: '',
      mobile: ''
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    const query = () => {
      store
        .dispatch(Actions.GET_ADD_EXTRA_POINT_RECORDS, {
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          console.log(store.getters.currentAddExtraPointRecords)
          tableData.value = store.getters.currentAddExtraPointRecords
          page.value.totalResult = store.getters.currentAddExtraPointRecordCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const submitEvent = () => {
      query()
    }

    const newAddExtraPointRecords = () => {
      router.push({ name: "add_extra_point_record_new" })
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.nickname = ''
      formData.value.mobile = ''
    }

    const onAbort = (id) => {
      Swal.fire({
        title: '确认作废？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let params = {
            id: id
          }

          store
            .dispatch(Actions.ABORT_ADD_EXTRA_POINT_RECORD, params)
            .then(() => {
              console.log('success')
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    return {
      tableData,
      formData,
      submitEvent,
      newAddExtraPointRecords,
      page,
      onPageChange,
      onCancel,
      onAbort
    }
  },
})
